import * as React from "react"

const FreeGuideModal = () => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btnFreeGuide d-none"
        data-bs-toggle="modal"
        data-bs-target="#freeGuide"
      >
        Free Guide
      </button>
      <div
        className="modal fade"
        id="freeGuide"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="freeGuideLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <span>Free Guide:</span>
                Top 10 Tips to Choose an Auto Mechanic
              </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <ul className="py-3">
                  <li>Dirty Tricks to watch out for</li>
                  <li>How to verify they're reputable</li>
                  <li>How to save money</li>
                </ul>
              </div>
              <div>
                <label className="mb-1">
                  Enter your email to download:
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Email or Phone" />
                    <button className="btn btnOrange">
                      <i className="bi bi-check-lg" /> Send it
                    </button>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreeGuideModal
