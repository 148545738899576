import * as React from "react"

const ExitIntentModal = () => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btnExitIntent d-none"
        data-bs-toggle="modal"
        data-bs-target="#exitIntentModal"
      >
        Exit intent
      </button>
      <div
        className="modal fade"
        id="exitIntentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exitIntentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body px-5 pt-4 pb-5">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              <div className="mb-3 text-center">
                <strong className="fs-3">Save on your next quote!</strong>
              </div>
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Email or Phone" />
                <button className="btn btnOrange">
                  <i className="bi bi-check-lg" /> Get My Coupon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExitIntentModal
