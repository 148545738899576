import * as React from "react"
import Map from "./map"

const Search = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-0">
          <div className="jumbotron  text-center mb-0 d-flex flex-column justify-content-center position-relative">
            <div className="row min-vh-100 g-0 align-items-center">
              <div className="col-lg-7 col-md-7 px-3">
                <h1 className="display-4">Best Auto Mechanics in Charleston</h1>
                <div className="pt-md-5 pt-3">
                  <form>
                    <div className="row">
                      <div className="col-xl-6 col-lg-8 mx-auto">
                        <h3 className="mb-3 VendorToClientLabel">
                          Where do you need your Auto Mechanic service?
                        </h3>
                        <h3 className="mb-3 ClientToVendorLabel">Search Nearby Auto Mechanics</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-8 mx-auto">
                        <div className="input-group">
                          <select className="form-select location-type">
                            <option value="Home">Home</option>
                            <option value="Office">Office</option>
                            <option value="Other">Other</option>
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Update Address & Search Nearby"
                          />
                          <button className="btn btn-lg btnSearch" type="button">
                            GO&nbsp;
                            <i className="bi bi-arrow-right-circle" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
