import * as React from "react"

const FreeAssistanceModal = () => {
  return (
    <div className="freeAssistanceModal">
      <div className="d-flex">
        <div className="iconBox">
          <i className="bi bi-clock" />
        </div>
        <div className="modal-box">
          <div className="mb-3">
            <h2>
              <span>Free Assistance</span>
              Want us to choose for you?
            </h2>
          </div>
          <div>
            <button className="btn btnOrange">
              <i className="bi bi-check-lg" /> Yes, I like saving time <i className="bi bi-clock" />
            </button>
            &nbsp;
            <button className="btn btnClose" id="btnCloseFreeAssistanceModal">
              <i className="bi bi-x-lg" /> No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreeAssistanceModal
