import * as React from "react"
import { Link } from "gatsby"

const PopularServices = () => {
  return (
    <section className="popularServices">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center mb-md-5 mb-3">
            <h2>Related Services in Charleston, SC</h2>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
            <div className="card">
              <Link to="#">
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Auto-Body.jpg"}
                  className="img-fluid"
                  alt="Auto Body"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to="#" className="d-block py-4">
                    <strong>Charleston Auto Body</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
            <div className="card">
              <Link to="#">
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Oil-Change.jpg"}
                  className="img-fluid"
                  alt="Oil Change"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to="#" className="d-block py-4">
                    <strong>Charleston Oil Change</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card">
              <Link to="#">
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Tire-Shops.jpg"}
                  className="img-fluid"
                  alt="Tire Shops"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to="#" className="d-block py-4">
                    <strong>Charleston Tire Shops</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopularServices
