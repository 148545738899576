import * as React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import HowItWorks from "../components/Index/howItWorks"
import PopularServices from "../components/List/popularServices"
import Faq from "../components/Index/faq"
import Search from "../components/List/search"
import Listing from "../components/List/listing"

import "../components/List/local_list.css"
import FreeGuideModal from "../components/List/freeGuideModal"
import FreeAssistanceModal from "../components/List/freeAssistanceModal"
import ExitIntentModal from "../components/List/exitIntentModal"

const AutoMechanicsPage = () => {
  return (
    <Layout page="category-template">
      <Seo title="Client To Vendor" />
      <Search />
      <Listing />
      <PopularServices />
      <Faq />
      <HowItWorks />
      <FreeGuideModal />
      <FreeAssistanceModal />
      <ExitIntentModal />
    </Layout>
  )
}

export default AutoMechanicsPage
