import * as React from "react"
import GoogleMapReact from "google-map-react"

const Map = () => {
  const defaultProps = {
    center: [40.712776, -74.005974],
    zoom: 5,
    greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
  }

  const AnyReactComponent = ({ text }) => <div>{text}</div>

  return (
    <div className="col-lg-5 col-md-5">
      <div className="map_container">
        <div id="local_map" style={{ width: "100%", height: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBvcAXg1ocsFA4q0Vj72erVYINdsi7vHDc" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={40.712776} lng={-74.005974} text={"Kreyser Avrora"} />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  )
}

export default Map
